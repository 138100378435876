import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Lightbox from "react-image-lightbox"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"

import LightboxImageWrapperCard from "../components/global/lightbox/lightbox-image-wrapper-card"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import Seo from "../components/global/seo/seo.jsx"
const SeperatorFiltresi = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(
        relativePath: { eq: "filtre-galeri/seperatorfiltresi8.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(
        relativePath: { eq: "filtre-galeri/seperatorfiltresi7.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim3: file(
        relativePath: { eq: "filtre-galeri/seperatorfiltresi2.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim4: file(
        relativePath: { eq: "filtre-galeri/flansliseperatorelegi.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
    data.resim3.childImageSharp.full.src,
    data.resim4.childImageSharp.full.src,
  ]
  const thumbArray = [
    { imgName: "resim2", imgAlt: "Seperatör Filtresi" },
    { imgName: "resim3", imgAlt: "Paslanmaz Seperatör Filtresi" },
    { imgName: "resim4", imgAlt: "Paslanmaz Seperatör Eleği" },
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Seperatör Filtresi - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz seperatör filtresi imalatı yapmaktadır. Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz seperatör filtresi imalatını gerçekleştirmekteyiz."
        keywords="Seperatör Filtresi,Seperatör Filtre,Seperatör eleği,Seperatör elek,Seperatör filtresi imalatı,Seperatör filtresi fiyatları,Seperatör eleği imalatı,Seperatör eleği fiyatı,Seperatör elek fiyatları,Seperatör filtre imalatı"
        url="seperator-filtresi"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box p={3}>
                <Typography component="h1" variant="h4">
                  <Box textAlign="center" fontWeight={500} mt={3}>
                    Paslanmaz Seperatör Filtresi
                  </Box>
                </Typography>
                <Lead>
                  Firmamız proje çizimine yada numuneye göre müşterilerimizin
                  istekleri doğrultusunda{" "}
                  <span>paslanmaz seperatör filtresi</span> imalatı yapmaktadır.
                </Lead>
                <Lead>
                  Göz aralıkları mikronlar ile ölçülebilen mesh telleri
                  kullanılarak istediğiniz ölçü, numune ya da projeye göre{" "}
                  <span>paslanmaz seperatör filtresi</span> imalatını
                  gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" py={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} py={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="Seperatör Filtresi"
                    imgClass=""
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box my={3}>
        <Grid container direction="row" spacing={3}>
          {thumbArray.map((resim, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div onClick={() => openLightBox(index + 1)}>
                <LightboxImageWrapperCard
                  fluid={data[resim.imgName].childImageSharp.thumb}
                  lightbox={true}
                  imgAlt={resim.imgAlt}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani
              title="Paslanmaz Pislik Tutucu Filtresi Kullanım Alanları"
              align="center"
            />
          </Box>
        </Paper>
      </Box>

      <Box my={3}>
        <FiltreGaleri />
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default SeperatorFiltresi
